<template>
  <div class="home">
    <img alt="Vue logo" width="600px" src="../assets/LOGOPREMIO.png">
    <div class="sorteio">
      <div v-if="isLoading" class="loading">
        Sorteando...
      </div>
      <div v-else class="resultado">
        Número sorteado: {{ numeroSorteado }}
      </div>
      <p v-if="ganhador.name"><b>Ganhador:</b> {{ ganhador.name }}</p>
      <p v-if="ganhador.numero"><b>Contato:</b> {{ ganhador.numero }}</p>
      <button @click="sortear" class="myButton">Sortear</button>
    </div>
    <div align="center" style="margin-top:100px">
      <hr></hr>
      <h2>Participantes</h2>
      <input type="text" v-model="filterText" placeholder="digite o número da sorte..." style="margin-bottom: 25px;">
      <table>
        <thead>
          <tr>
            <th>Número da Sorte</th>
            <th>Nome</th>
            <th>Telefone</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(inscrito, index) in paginatedInscritos" :key="inscrito.numero">
            <td>{{ inscrito.codigo }}</td>
            <td>{{ inscrito.name }}</td>
            <td>{{ inscrito.numero }}</td>
          </tr>
        </tbody>
      </table>
      <button class="myButton2" @click="currentPage > 1 && currentPage--">Antes</button>
      <button class="myButton2" style="margin-left:15px; margin-bottom: 15px" @click="currentPage < totalPages && currentPage++">Próximo</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      ganhador: {},
      numbers: [],
      currentPage: 1,
      itemsPerPage: 30,
      filterText: '',
      isLoading: false,
      numeroSorteado: null,
      inscritos:
        [   { name: 'Maria Aparecida Schramm Pereira de Moura ', numero: '75992487208' }
          , { name: 'Gessica soares ', numero: '75998154763' }
          , { name: 'Angélica Rodrigues Fernandes ', numero: '11932674230' }
          , { name: 'Mônica Portugal ', numero: '75988131219' }
          , { name: 'Lais de jesus ', numero: '71991386738' }
          , { name: 'Amanda Marinho ', numero: '75988383530' }
          , { name: 'Vanessa Furlani ', numero: '71991878494' }
          , { name: 'Mariana Lima Pimentel', numero: '75 991434490' }
          , { name: 'Ruthielly Silva', numero: '75999958250' }
          , { name: 'Janete ', numero: '71997313973' }
          , { name: 'Bruno schramm', numero: '71997313973' }
          , { name: 'Tania Maria Cerqueira de Moura ', numero: '71 993775031 ' }
          , { name: 'Lucimary Ribeiro Couti', numero: '71996259881' }
          , { name: 'Bruno Schramm', numero: '71996468995' }
          , { name: 'Ricardo de Santana Lima ', numero: '71 99137-1402 ' }
          , { name: 'Lisiane Alves ', numero: '75998346018' }
          , { name: 'Grace Chely ', numero: '11945886569' }
          , { name: 'Edna Cerqueira de Moura', numero: '988051274' }
          , { name: 'Everton moura ', numero: '75982376038' }
          , { name: 'Ricardo Queiroz ', numero: '71993108338' }
          , { name: 'Ricardo Queiroz ', numero: '71993108338' }
          , { name: 'Fabiana Lima ', numero: '75999956899' }
          , { name: 'Magali Rocha Carneiro ', numero: '75999939085' }
          , { name: 'Cessiane Damasceno Flores ', numero: '75998017547' }
          , { name: 'Ladja Nascimento Souza ', numero: '75 99994-1333 ' }
          , { name: 'Iran Santos Aquino ', numero: '75999926605' }
          , { name: 'Iasmin santos evangelista ', numero: '(75)999894317' }
          , { name: 'Elizete dos Santos ', numero: '7598353 3634' }
          , { name: 'Adriano Moura', numero: '75988161035' }
          , { name: 'Bruna Celli Góes Lima Santos', numero: '75999534342' }
          , { name: 'Maria Elena Da costa Lima', numero: '71991236711' }
          , { name: 'Jonnatan Brenner santos rabelo ', numero: '75998393489' }
          , { name: 'Dailma Pereira de Souza ', numero: '71999866751' }
          , { name: 'Júlia Cristina Souza Gaudêncio ', numero: '75998293677' }
          , { name: 'Amanda de Souza Santana ', numero: '75 98115-9576 ' }
          , { name: 'Rousana Ferreira De Santana', numero: '75991768916' }
          , { name: 'Fabiana Pimentel ', numero: '75 99143-4498 ' }
          , { name: 'Edinalva ', numero: '75998465132' }
          , { name: 'Marcos  Antonio do Nascimento Souza', numero: '75 999833101' }
          , { name: 'Marciele dos santos ', numero: '75998762815' }
          , { name: 'Iasmim dos Santos Moreira Brito ', numero: '75991743592' }
          , { name: 'Eliane Silva de Jesus Cruz ', numero: '75 98147-7985' }
          , { name: 'Valtenice Silva Menezes ', numero: '7599856146' }
          , { name: 'Mireclecia dos Santos pinto ', numero: '75983625807' }
          , { name: 'Sintia Maria Conceição de Oliveira ', numero: '75992164988' }
          , { name: 'Jussara dos Santos Araújo Silva', numero: '75 998073851' }
          , { name: 'Sandy', numero: '75981347869' }
          , { name: 'Ivana Santos Cardoso Nascimento ', numero: '75 98237-6947 ' }
          , { name: 'Juliana Anjos ', numero: '75982359625' }
          , { name: 'Thialla Costa santos', numero: '75999666628' }
          , { name: 'Thialla Costa santos', numero: '75999666628' }
          , { name: 'Willyan dos anjos silva', numero: '77981012307' }
          , { name: 'Rejane Sales Ratis', numero: '75992272124' }
          , { name: 'Neuma Santos Almeida', numero: '75983011097' }
          , { name: 'Aline dos Santos Araújo ', numero: '75999911525' }
          , { name: 'Aline dos Santos Araújo ', numero: '75999911525' }
          , { name: 'Normando ', numero: '75999881554' }
          , { name: 'Kamilayne Rosa Dos Santos ', numero: '75998407948' }
          , { name: 'Kamilayne Rosa Dos Santos ', numero: '75998407948' }
          , { name: 'Maria Izabel Dos Reis ', numero: '75999866678' }
          , { name: 'Juliana de Jesus Barros Alcântara ', numero: '75999363668' }
          , { name: 'Ramon Reis ', numero: '75 99802 4628' }
          , { name: 'Heloísa da Silva Santos ', numero: '75981167134' }
          , { name: 'Carmina coelho de Siqueira ', numero: '75981919991' }
          , { name: 'Simone Doria de Souza ', numero: '75 999995908 ' }
          , { name: 'Patrícia Marques ', numero: '75983739032' }
          , { name: 'Patrícia Reis ', numero: '983739032' }
          , { name: 'Roberto Cravo ', numero: '75998165862' }
          , { name: 'Andaraci Souza Cavalcante de Albuquerque ', numero: '75 998014681' }
          , { name: 'Andaraci ', numero: '75 998014681' }
          , { name: 'Rejane Sales Ratis', numero: '75 992272124' }
          , { name: 'Jucimar ', numero: '75999669726' }
          , { name: 'Claudia Schramm Santana ', numero: '75981272491' }
          , { name: 'Madson Lisboa Oliveira ', numero: '75982648114' }
          , { name: 'Madson Lisboa Oliveira ', numero: '75982648114' }
          , { name: 'Vanessa Aniz da Rosa ', numero: '75999319126' }
          , { name: 'Joilma Barbosa Figueiredo de oliveira', numero: '75988380734' }
          , { name: 'Carolina ', numero: '71991833711' }
          , { name: 'Marcelo dos Santos Conceição ', numero: '75 99952-6066 ' }
          , { name: 'Marcelo Santos ', numero: '75 999526066 ' }
          , { name: 'Marcelo santos', numero: '75 999526066 ' }
          , { name: 'Daniele diogo', numero: '75982199753' }
          , { name: 'Daniele diogo ', numero: '75982199753' }
          , { name: 'Sylvia Maria Cerqueira de Moura Batista', numero: '75999649704' }
          , { name: 'Márcio Antônio da Silva Batista ', numero: '75999838416' }
          , { name: 'Luiz Fernando Carvalho silva ', numero: '75999711531' }
          , { name: 'Geisa Gusmão Bastos ', numero: '75998271841' }
          , { name: 'EVA VILMA SANTANA DE JESUS', numero: '75 9 9992 3548' }
          , { name: 'MARINALVA DOS SANYOS', numero: '75999229351' }
          , { name: 'Antônia Teixeira dos Santos ', numero: '75981569261' }
          , { name: 'Rodrigo Santana Costa Lima ', numero: '79 998479515' }
          , { name: 'Jaci da Conceição ', numero: '7183133876' }
          , { name: 'Adilza da Silva ', numero: '7598292-6399 ' }
          , { name: 'Adilza da Silva ', numero: '7598292-6399 ' }
          , { name: 'Sidnei Farias de Souza ', numero: '75998361612' }
          , { name: 'Eduardo da Silva Almeida ', numero: '75999956165' }
          , { name: 'EDNALVA FERREIRA DOS SANTOS ', numero: '75998437048' }
          , { name: 'Sirleide correia', numero: '75999678564' }
          , { name: 'Izabel pinto', numero: '983686668' }
          , { name: 'Ivonildo jeremias de Oliveira ', numero: '7597599611806' }
          , { name: 'normando', numero: '75999881554' }
          , { name: 'Vera Lucia Batista Teixeira ', numero: '75998394864' }
          , { name: 'Silas Batista Teixeira ', numero: '75998193850' }
          , { name: 'Leandro de Moura Lima ', numero: '71991072269' }
          , { name: 'João Paulo dois santos Gramacho', numero: '75 981079140' }
          , { name: 'Lúcia Maria Dantas Barbosa ', numero: '74988178453' }
          , { name: 'Ana Lúcia Dantas de Oliveira ', numero: '74 988154243 ' }
          , { name: 'Ana Lúcia Dantas de Oliveira ', numero: '74 988154243 ' }
          , { name: 'Maiara da Silva Santana ', numero: '75999348967' }
          , { name: 'Maiara da Silva Santana ', numero: '75999348967' }
          , { name: 'Josefa Ana Cacia Santos ', numero: '79998933041' }
          , { name: 'Joelice  da Silva Lima Carneiro', numero: '75981547639' }
          , { name: 'Luiz Gustavo ', numero: '71983410107' }
          , { name: 'Anaildes Oliveira de Carvalho Rocha ', numero: '75992406811' }
          , { name: 'Ticiane Queiroz Negreiros ', numero: '75998045660' }
          , { name: 'gustavo souza santos', numero: '71987068472' }
          , { name: 'Danilo Brito Borges', numero: '75 99156-1756 ' }
          , { name: 'Daile Xavier Soares ', numero: '75988516673' }
          , { name: 'Lidiane Ramos Brito de Souza Aires ', numero: '75991881192' }
          , { name: 'Danilo Brito Borges ', numero: '75991561756' }
          , { name: 'Tereza Cristina Brito Xavier de Jesus ', numero: '75999226192' }
          , { name: 'Lucy Conceição dos Santos Barreto ', numero: '75992401405' }
          , { name: 'Luciano da Silva Carmo ', numero: '71992481419' }
          , { name: 'Hélida Batista dos Santos ', numero: '75991335024' }
          , { name: 'Jandira Xavier Ferreira ', numero: '75 98827-6700 ' }
          , { name: 'Dilza Maria de Jesus Xavier ', numero: '75988493024' }
          , { name: 'Jessica ', numero: '7599829987' }
          , { name: 'Marluce Ramos de Santana ', numero: '75983025019' }
          , { name: 'Cleide dos Anjos Figueredo ', numero: '75998941043' }
          , { name: 'Ricardo', numero: '75 99874-4358 ' }
          , { name: 'Jessica de Menezes ', numero: '73998332443' }
          , { name: 'Danilo Brito Borges', numero: '75991561756' }
          , { name: 'Marcos Vinicius Cerqueira Santos', numero: '75991271507' }
          , { name: 'Elizabeth de Jesus Araújo ', numero: '75 98116-0390' }
          , { name: 'Joelson Carvalho Barbosa ', numero: '75999208383' }
          , { name: 'Jamile dos Anjos Conceição ', numero: '75999930112' }
          , { name: 'Karoline Garcia', numero: '11982068860' }
          , { name: 'Erick Luiz de Souza Pinto', numero: '75981300498' }
          , { name: 'Ismael cruz', numero: '75 998411301' }
          , { name: 'Jociane dos Reis Cruz ', numero: '71991778875' }
          , { name: 'Janete santos', numero: '75998850329' }
          , { name: 'Taise de jesus lima ', numero: '75988622603' }
          , { name: 'Madjane Oliveira ', numero: '75981671351' }
          , { name: 'Rosana Torres Marques ', numero: '79988352114' }
          , { name: 'Luzia de Souza Torregrossa Barreto ', numero: '73991911851' }
          , { name: 'Jordana Gabriela Barreto de Sá', numero: '75998368650' }
          , { name: 'Valnei ', numero: '75 99930-9000 ' }
          , { name: 'Andrea  de S. Barros ', numero: '71 98896-3608 ' }
          , { name: 'Jéssica Chiarelli ', numero: '7599829987' }
          , { name: 'Adenilza Reis Souza ', numero: '75998036970' }
          , { name: 'Adenilza Reis Souza ', numero: '75998036970' }
          , { name: 'Edmário Vieira de Melo ', numero: '75 999993144' }
          , { name: 'Adenilza Reis Souza ', numero: '75998036970' }
          , { name: 'Geisa dos Santos Figueredo ', numero: '75982738709' }
          , { name: 'Lílian dos Santos Sales ', numero: '75999590085' }
          , { name: 'Núbia Leticia Santos de Souza ', numero: '75991380488' }
          , { name: 'Genilza dos Santos de Jesus ', numero: '75988984752' }
          , { name: 'Ana Carine Franco', numero: '75992163834' }
          , { name: 'Rosana Silva ', numero: '75981806824' }
          , { name: 'Lukas de Menezes ', numero: '75999600412' }
          , { name: 'Fabiola Souza cerqueira ', numero: '75997018597' }
          , { name: 'Silvane Cardoso ', numero: '75999329480' }
          , { name: 'Irene Martins', numero: '75992048852' }
          , { name: 'Evanilda de Jesus Soares ', numero: '71992449856' }
          , { name: 'Marco Aurelio de Oliveira e Souza', numero: '75999315290' }
          , { name: 'Edjan dos Santos Silveira ', numero: '71996177095' }
          , { name: 'Emile Carolline', numero: '75 981151528 ' }
          , { name: 'Dayvson Cerqueira Carvalho', numero: '75991372096' }
          , { name: 'Leandro dos Santos Castro Oliveira ', numero: '7599936-8639' }
          , { name: 'Alexsandro de Jesus Xavier ', numero: '75981124474' }
          , { name: 'Marinalva de Souza Pereira ', numero: '75981868261' }
          , { name: 'Caliana Marla Aragão Costa ', numero: '75982018586' }
          , { name: 'Daiana Dias Lima Subotovsky ', numero: '71983216912' }
          , { name: 'Kall lutkenhaus ', numero: '31 990651742' }
          , { name: 'Madson Lisboa Oliveira ', numero: '759 8264 8114 ' }
          , { name: 'Marinalva de Souza Pereira ', numero: '75981868261' }
          , { name: 'Maria de Jesus Lima da Silva ', numero: '99992090668' }
          , { name: 'Diogo Santana de Missias Cerqueira ', numero: '075981848887' }
          , { name: 'Vera Lúcia Santos da Silva ', numero: '7599965-8326 ' }
          , { name: 'Izael Chrystian Sousa de Oliveira ', numero: '11962649874' }
          , { name: 'Aida Pires', numero: '75999927115' }
          , { name: 'Lucidalva s,santos', numero: '(75)998858464' }
          , { name: 'Ivanisio Torres Moreira ', numero: '71997292848' }
          , { name: 'Dailton ', numero: '75988567343' }
          , { name: 'Marileide dos Anjos Figueredo ', numero: '98897265' }
          , { name: 'André de A. Nascimento ', numero: '75 991026329' }
          , { name: 'Anna Karoline Santana Oliveira ', numero: '75981723933' }
          , { name: 'Evila Alves ', numero: '75999044536' }
          , { name: 'Elton Rocha ', numero: '71 99104 8494 ' }
          , { name: 'Helbert Marques ', numero: '71999099149' }
          , { name: 'Jose Domingos de jesus xavier', numero: '75 988084652' }
          , { name: 'Reijane Figueredo ', numero: '75999614550' }
          , { name: 'Msria Celia Costa', numero: '7199y250129' }
          , { name: 'Taiara Sales', numero: '73988829552' }
          , { name: 'Quezia de Miranda Dias Silva ', numero: '75992334099' }
          , { name: 'Mateus Henrique ', numero: '75981604090' }
          , { name: 'José Moisés de Lima Nascimento ', numero: '75 981070462' }
          , { name: 'Jamile dos anjos', numero: '75988760612' }
          , { name: 'Mirlane de Jesus Xavier ', numero: '75988841973' }
          , { name: 'Jaiane santos lima', numero: '7598252-1378' }
          , { name: 'Luana de Almeida Santos ', numero: '75982090232' }
          , { name: 'Maiellemarxes da Silva Araújo ', numero: '75999454700' }
          , { name: 'Antônio Marcos ', numero: '79983423007' }
          , { name: 'Sônia Regina dos Santos Braga ', numero: '71985251369' }
          , { name: 'Thamiris Lima Gonçalves Soares ', numero: '71988033529' }
          , { name: 'Mayara Mariana Pereira do Nascimento ', numero: '75981981866' }
          , { name: 'Ariana Francisca da Silva ', numero: '75999261521' }
          , { name: 'ERIKA CAMILA COSTA  DE ARAÚJO ', numero: '75 98351-5174 ' }
          , { name: 'Jessilene da Silva Araújo ', numero: '75 9 81406447' }
          , { name: 'Jessilene da Silva Araújo ', numero: '75 981406447' }
          , { name: 'Claudinei ', numero: '75 91183305 ' }
          , { name: 'Anancy dos Anjos coelho ', numero: '71992938348' }
          , { name: 'Estefani', numero: '71988828236' }
          , { name: 'CATIA JANAINA M DA SILVA ', numero: '71999335066' }
          , { name: 'Dilane leal ', numero: '75999500047' }
          , { name: 'Rosilene Machado dos Santos ', numero: '79988256239' }
          , { name: 'Ludilaine Santos de Santana ', numero: '75983468908' }
          , { name: 'Clemilda Oliveira Souza Albuquerque ', numero: '75999394363' }
          , { name: 'Andréia Ramos Ferreira ', numero: '75981941895' }
          , { name: 'Dayane Jesus Santos ', numero: '75981934273' }
          , { name: 'Gabriel de Jesus Silva ', numero: '67981241885' }
          , { name: 'Ariela dos Reis Canton de Albuquerque ', numero: '75998291383' }
          , { name: 'Waldejose Souza Cavalcante de Albuquerque ', numero: '75999056831' }
          , { name: 'Maria Eduarda Canton Cavalcante de Albuquerque ', numero: '75 998475171' }
          , { name: 'Maria Vallentina Canton Cavalcante de Albuquerque ', numero: '75999037609' }
          , { name: 'Daniela Ribeiro da Silva ', numero: '75988602831' }
          , { name: 'Anilza de Araújo ', numero: '75991026329' }
          , { name: 'Ualaci Borges Soares ', numero: '71992279663' }
          , { name: 'Ianka ', numero: '79998417051' }
          , { name: 'Larissa ', numero: '75983424736' }
          , { name: 'Larissa Silva Cardoso ', numero: '75997167339' }
          , { name: 'José Faustino Pinto Filho ', numero: '75991648657' }
          , { name: 'Ana Laura Alves Reis de Oliveira Souza', numero: '75992500528' }
          , { name: 'Antonio Roberto ', numero: '75 99215-0233' }
          , { name: 'ANTONIETA DOS SANTOS ', numero: '75981975343' }
          , { name: 'Maria Cristina Machado dos Santos', numero: '79 988711190' }
          , { name: 'Karine Matos Santos Rodrigues ', numero: '75 983686369' }
          , { name: 'Juliana ', numero: '75998068373' }
          , { name: 'Adriana araujo', numero: '75991572372' }
          , { name: 'Vanessa de Oliveira Cerqueira Silva ', numero: '75 998073115' }
          , { name: 'Anderson Gomes de Araújo ', numero: '998391430' }
          , { name: 'Beatriz Santos Bitencourt ', numero: '75 983531963' }
          , { name: 'ERIKA CAMILA COSTA DE ARAÚJO ', numero: '75 98351-5174 ' }
          , { name: 'Silvaneres Ferreira de Souza ', numero: '75983159891' }
          , { name: 'Suellen carvalho bispo dos Santos ', numero: '75992600607' }
          , { name: 'Evanildes coelho rocha ', numero: '71982122336' }
          , { name: 'Naiara lena souza', numero: '759835159' }
          , { name: 'João Lima dos anjos ', numero: '75998885758' }
          , { name: 'Marluce dos Santos Ramos ', numero: '75999262670' }
          , { name: 'Daniela Ferreira ', numero: '75991025065' }
          , { name: 'Silvia ', numero: '75983030875' }
          , { name: 'Sandra dos anjos lima ', numero: '75981742385' }
          , { name: 'Mariluce Santos ', numero: '75981830241' }
          , { name: 'Lucileide lima dantas ', numero: '75981433500' }
          , { name: 'Lucileide lima dantas ', numero: '75981433500' }
          , { name: 'Alexsandra Paulo ', numero: '75982912728' }
          , { name: 'Maria Cristina de Oliveira Santos ', numero: '71983866952' }
          , { name: 'Jamile Hora', numero: '75991559655' }
          , { name: 'Joselinda Maria de Oliveira ', numero: '71992139134' }
          , { name: 'Rutileia Oliveira ', numero: '75983229144' }
          , { name: 'Nattiel Cardoso ', numero: '75988285624' }
          , { name: 'Edlene', numero: '75982865529' }
          , { name: 'Josemar Moura Barreto ', numero: '75997045213' }
          , { name: 'Cíntia Moura dos Santos ', numero: '75 9 81275669 ' }
          , { name: 'Jair dos anjos Conceição ', numero: '7599127-8698 ' }
          , { name: 'Jair dos anjos Conceição ', numero: '7599127-8698 ' }
          , { name: 'Danilo Oliveira Boaventura ', numero: '075982419147' }
          , { name: 'Carlos alberto dos santos lima', numero: '071983441030' }
          , { name: 'Lidiane souza dos santos ', numero: '22 9 92545610' }
          , { name: 'Tamilys Andrade de Jesus', numero: '75982360620' }
          , { name: 'Gilvan Franco dos Santos ', numero: '75991209832' }
          , { name: 'ISRAEL MARINHO DA GAMA ', numero: '75 9 9263-8432' }
          , { name: 'Helena Laís de Almeida Santana ', numero: '71983429405' }
          , { name: 'Ana Paula do Nascimento Araújo ', numero: '75998731420' }
          , { name: 'Vanessa Maria de Pinho Ferreira ', numero: '71999503258' }
          , { name: 'Jessimar Araújo Reis ', numero: '75991268744' }
          , { name: 'Caliane Almeida do Couto Santos', numero: '75999561858' }
          , { name: 'Tania Faleta', numero: '75983411943' }
          , { name: 'Milena de Sena Souza Cardoso ', numero: '75 98873-8978 ' }
          , { name: 'Maria Lúcia Freitas de Pinho Ferreira ', numero: '71992365923' }
          , { name: 'Maricleide de Pinho Ferreira ', numero: '71996115898' }
          , { name: 'Jaquisson ', numero: '981775607' }
          , { name: 'Miguel ', numero: '71991439010' }
          , { name: 'Joselia bento dos Santos ', numero: '47988399566' }
          , { name: 'Josilda santos costa ', numero: '75981264140' }
          , { name: 'Evelly Vieira ', numero: '75983349090' }
          , { name: 'Rafael Santos costa ', numero: '75981343015' }
          , { name: 'Keire Cedro', numero: '75991210357' }
          , { name: 'Joselia bento dos Santos ', numero: '47988399566' }
          , { name: 'joanice pereira dos santos vieora', numero: '75992209774' }
          , { name: 'Alex Paulo ', numero: '75 99125 0860 ' }
          , { name: 'Joselito Cardoso Peleteiro ', numero: '75 9 8805-4516 ' }
          , { name: 'LAIANA DA CONCEIÇÃO SANTOS ', numero: '75997069143' }
          , { name: 'LAIANA DA CONCEIÇÃO SANTOS ', numero: '75997069143' }
          , { name: 'Gilvan Franco dos Santos ', numero: '75991209832' }
          , { name: 'Cláudio paim Alves Sena ', numero: '7598282-3766 ' }
          , { name: 'Cláudio paim Alves Sena ', numero: '7598282-3766 ' }
          , { name: 'Jamile dos anjos ', numero: '75982623443' }
          , { name: 'Regilane', numero: '75981012338' }
          , { name: 'Aline Gomes ', numero: '75999143162' }
          , { name: 'Gabrielli Pereira Gomes', numero: '75988393852' }
          , { name: 'Josefa ', numero: '75988397881' }
          , { name: 'Josefa ', numero: '75988397881' }
          , { name: 'Ivanira Gouveia Pereira ', numero: '7591822359' }
          , { name: 'Rodrigo Reis Ramos ', numero: '71984841655' }
          , { name: 'Jamile dos Anjos ', numero: '75981732832' }
          , { name: 'Eduardo Henrique Oliveira dos Santos ', numero: '75991241521' }
          , { name: 'Ana Luíza ', numero: '75983369741' }
          , { name: 'THALLIA DOS SANTOS COSTA ', numero: '71996340941' }
          , { name: 'Eder Lucio de Jesus Silva ', numero: '75998279729' }
          , { name: 'Valter Nilson Pereira dos Santos Filho', numero: '73 99118-2000' }
          , { name: 'Rejane Sales Ratis', numero: '75992272124' }
          , { name: 'Rosenildes vieira silveira ', numero: '75983065408' }
          , { name: 'Raquel da Cruz Santana Rodrigues ', numero: '75981125725' }
          , { name: 'Jaynna silveira ', numero: '75999343118' }
          , { name: 'Andiara Araujo da paixão ', numero: '71993991400' }
          , { name: 'Jarildes vieira silveira santos ', numero: '75999835837' }
          , { name: 'Maria da Glória  Santana  Mansur Dias', numero: '75 998226339' }
          , { name: 'Edna Santos Oliveira ', numero: '75981563022' }
          , { name: 'ERIKA CAMILA COSTA DE ARAÚJO ', numero: '75 98351-5174 ' }
          , { name: 'Cristiane Batista Santos silva ', numero: '75 98833-5210 ' }
          , { name: 'Paulo Sérgio da silva ', numero: '75999457614' }
          , { name: 'Vanessa ', numero: '75998073115' }
          , { name: 'Lívia Ramos ', numero: '75981861942' }
          , { name: 'Jéssica Nataline Santos Simões', numero: '75988571089' }
          , { name: 'Diego Santos ', numero: '75991324625' }
          , { name: 'EVA VILMA SANTANA DE JESUS', numero: '75999923548' }
          , { name: 'Jaciane Costa Santos ', numero: '75982942361' }
          , { name: 'MARIA NEUZA SANTANA DE JESUS', numero: '75998301812' }
          , { name: 'Ana Lúcia Santos Alves ', numero: '75 99914-1329' }
          , { name: 'Nadiele ', numero: '75982400722' }
          , { name: 'Ademildo de jesus costa', numero: '75992845062' }
          , { name: 'Leticia Cocenza ', numero: '15998132799' }
          , { name: 'Brenda Emanuelly da Silva Araújo ', numero: '75997058307' }
          , { name: 'Rosimeire da Silva Rodrigues ', numero: '75988089892' }
          , { name: 'Darlane Macedo Santos ', numero: '75992433045' }
          , { name: 'Lidiane Sacramento Soares', numero: '75983480961' }
          , { name: 'Andreia santos do nascimento ', numero: '75 98205-7361' }
          , { name: 'Roseane Pereira da Silva Santos ', numero: '62 996707231' }
          , { name: 'Ana selma dos Santos ', numero: '75 983371415' }
          , { name: 'Rafael Silvério Furtado', numero: '75981023124' }
          , { name: 'Silvia Carla Mendes', numero: '75999860236' }
          , { name: 'Janaina Moura Santos', numero: '75983203872' }
          , { name: 'Ludimile de Araújo ', numero: '75992901326' }
          , { name: 'Kellen vieira dias de souza ', numero: '981919393' }
          , { name: 'Gabriele Rodrigues ', numero: '71992009223' }
          , { name: 'Vanessa Rodrigues ', numero: '71997375941' }
          , { name: 'Samuel Silva dias Bastos ', numero: '75991474448' }
          , { name: 'Rosângela Silva Oliveira Bastos ', numero: '75988555147' }
          , { name: 'Talita dos Santos ', numero: '75991872690' }
          , { name: 'Talita dos Santos ', numero: '75991872690' }
          , { name: 'Luiza Almeida ', numero: '74991059040' }
          , { name: 'Vinicius', numero: '75992026700' }
          , { name: 'Vinicius Faustino', numero: '75992026700' }
          , { name: 'Vinicius', numero: '75992026700' }
          , { name: 'luzia', numero: '75 999959042 ' }
          , { name: 'José Carlos Santos Nunes ', numero: '75981177615' }
          , { name: 'Adjane Souza Costa dos Santos ', numero: '75 982019762' }
          , { name: 'Adjane Souza Costa dos Santos ', numero: '75982019762' }
          , { name: 'Milena Jatahi Bispo', numero: '75982445954' }
          , { name: 'Francilene santos magalhaes', numero: '75988459283' }
          , { name: 'Manoel Gomes', numero: '75982560214' }
          , { name: 'Marilda Batista ', numero: '75999389755' }
          , { name: 'Antonio Moura ', numero: '75982821142' }
          , { name: 'Antonio Bruno Schramm', numero: '75982821142' }
          , { name: 'Edielson Neiva Santana ', numero: '75981975581' }
          , { name: 'Carla dos Santos pinto', numero: '75983303895' }
          , { name: 'Taline dos Santos Pinto ', numero: '75983131659' }
          , { name: 'Carla dos Santos pinto', numero: '75983303895' }
          , { name: 'Viviane Teles de Menezes ', numero: '71981654923' }
          , { name: 'Daniela da conceição reis ', numero: '71982169275' }
          , { name: 'Mânia Manuelle Batista sobrinho ', numero: '75999487981' }
          , { name: 'Cristiane Cerqueira Santos e Santos ', numero: '75999856263' }
          , { name: 'Gabriela Gouveia ', numero: '71991535573' }
          , { name: 'Guilherme vitorio de Araújo Almeida ', numero: '75981623505' }
          , { name: 'Rosangela Alves dos Santos ', numero: '75981623050' }
          , { name: 'Suhelen Cirena dos Santos Alves ', numero: '75998409165' }
          , { name: 'Tânia Carla Barbalho ', numero: '75988350857' }
          , { name: 'Jessica dos Santos Nascimento ', numero: '71984349681' }
          , { name: 'Francielly Andrade ', numero: '75999978422' }
          , { name: 'Carlisson Dantas ', numero: '75983364759' }
          , { name: 'Juliana Fraga Santos ', numero: '(79) 999732140' }
          , { name: 'Anabel Goes soares', numero: '75999951514' }
          , { name: 'Crispina Batista de Macedo ', numero: '75982605405' }
          , { name: 'David Augusto', numero: '75999832078' }
          , { name: 'Maria Rita de Carvalho Sousa ', numero: '75 981251561' }
          , { name: 'Lucileide  Santos Silva ', numero: '75983138194' }
          , { name: 'Rosângela santos de Oliveira ', numero: '75 998951403' }
          , { name: 'Antônio de Pádua costa', numero: '88927043' }
          , { name: 'Valdir Jose cordeiro ', numero: '71 98187-6407 ' }
          , { name: 'Antônio de Pádua costa ', numero: '88926043' }
          , { name: 'Alinne Pinheiro ', numero: '75997056259' }
          , { name: 'Marília Santos da Conceição ', numero: '71985021544' }
          , { name: 'Clesiane Pereira Serra ', numero: '75983009620' }
          , { name: 'Jéssica Nataline Santos Simões ', numero: '75988571089' }
          , { name: 'Jessica Nataline Santos Simões ', numero: '75988571089' }
          , { name: 'Maisa Viana Almeida Sousa ', numero: '79988489970' }
          , { name: 'Rosana Santos Andrade de Meireles ', numero: '71991201348' }
          , { name: 'Otavaney de meireles batista', numero: '71981859276' }
          , { name: 'Rayssa Barbosa Barros', numero: '75982327796' }
          , { name: 'Tânia Maria Silva Ribeiro ', numero: '75998247453' }
          , { name: 'Verônica pereira Souza ', numero: '75/988860156' }
          , { name: 'Verônica pereira Souza ', numero: '75/88860156' }
          , { name: 'Sebastião Gaudêncio ', numero: '71981451680' }
          , { name: 'Erivaldo Ramos dos Anjos ', numero: '75982999208' }
          , { name: 'Monique malaquias estevam ', numero: '11 96727-2191 ' }
          , { name: 'Maria Cristina Costa severo ', numero: '75.98806-5211 ' }
          , { name: 'Andréa Souza', numero: '75981558050' }
          , { name: 'Daniela Araujo de Souza ', numero: '75991486503' }
          , { name: 'Rafaela de Santana Ramos ', numero: '75991857787' }
          , { name: 'ELIETE Macedo dos Santos Pacheco ', numero: '71987707697' }
          , { name: 'esther sacramento ', numero: '75998500378' }
          , { name: 'Ana Beatriz Martins Ribeiro ', numero: '98981771892' }
          , { name: 'Nazaniel de Moura Rabelo ', numero: '75 9 9937 6146 ' }
          , { name: 'Paula', numero: '75999534421' }
          , { name: 'Valéria dos Santos ', numero: '75983424217' }
          , { name: 'Lucas de Lima Matos ', numero: '71999542537' }
          , { name: 'Eliene de Lima Santos', numero: '71999542537' }
          , { name: 'Matheus Soares Silva', numero: '75998649673' }
          , { name: 'Valter de Matos Santos Filho ', numero: '75992689069' }
          , { name: 'Rosiane Lima Evangelista de Jesus ', numero: '75988491011' }
          , { name: 'Deni Maia dos Santos ', numero: '75981104542' }
          , { name: 'Samai Gomes', numero: '71997094497' }
          , { name: 'Lucileide Costa ', numero: '75992504887' }
          , { name: 'Tarcio Santos Carvalho ', numero: '75982233219' }
          , { name: 'Emilia Cristina Pereira Carvalho ', numero: '75992099045' }
          , { name: 'Maria Das Dores Santos Carvalho ', numero: '75998334370' }
          , { name: 'Amarildo Tavares Carvalho ', numero: '75981900015' }
          , { name: 'Maria Das Dores Santos Carvalho ', numero: '75998334370' }
          , { name: 'Tarcio Santos Carvalho ', numero: '7598223219' }
          , { name: 'Tarcio Santos Carvalho ', numero: '75982233219' }
          , { name: 'Lais Cruz Alves ', numero: '75998279031' }
          , { name: 'Caio Vinicius Batista Santos ', numero: '21976230679' }
          , { name: 'Roque Antônio de Oliveira ', numero: '75999834346' }
          , { name: 'MELYSSA RHUANNA QUEIROZ DA SILVA', numero: '75992315141' }
          , { name: 'Fabiola Souza cerqueira ', numero: '75997018597' }
          , { name: 'Eliene de Lima Santos ', numero: '72999542537' }
          , { name: 'Aline Bispo Borges ', numero: '981785532' }
          , { name: 'Aline Bispo ', numero: '75981785532' }
          , { name: 'Massimo Cauã Cavalcante de Albuquerque Canton', numero: '75998145818' }
          , { name: 'Mariana Santos Amy Reis ', numero: '75992995854' }
          , { name: 'Mariana Santos Amy Reis ', numero: '75992995854' }
          , { name: 'Daniele Florencio ', numero: '75999677204' }
          , { name: 'Henrique Brito', numero: '75 982545311' }
          , { name: 'Gildson Oliveira Damiao ', numero: '75999023808' }
          , { name: 'Walterlicia', numero: '75992162988' }
          , { name: 'Elenilda dos Santos costa ', numero: '75 999512775 ' }
          , { name: 'Elenilda ', numero: '75 999512775 ' }
          , { name: 'EDIVALDO PEDRO DOS SANTOS ', numero: '75988784804' }
          , { name: 'Jamile da conceição ', numero: '75983715463' }
          , { name: 'GILVANETE  BARBORSA DA SILVA ', numero: '75992056628' }
          , { name: 'Edna siva', numero: '75 98763722' }
          , { name: 'Jainara ', numero: '7598355-4176 ' }
          , { name: 'Marta ', numero: '75 999550152 ' }
          , { name: 'Jonhkeileson', numero: '75991923427' }
          , { name: 'Marta lucia da silva', numero: '71992957026' }
          , { name: 'Thalita Cristina Pereira ', numero: '15981645607' }
          , { name: 'Naise ', numero: '75998609386' }
          , { name: 'Roberto Oliveira ', numero: '998035166' }
          , { name: 'Julia', numero: '75983502597' }
          , { name: 'Julia Gaudêncio', numero: '75983502597' }
          , { name: 'Julenice Rita de Santana Santos ', numero: '71 99206-9725 ' }
          , { name: 'Marcio Cerqueira dos Santos ', numero: '71981230736' }
          , { name: 'Maria Natividade Souza de Jesus ', numero: '75 9 9996 9964' }
          , { name: 'María argentina', numero: '75999840736' }
          , { name: 'Joaopaulo ', numero: '75983274591' }
          , { name: 'Jamile da conceição Alves ', numero: '75983715463' }
          , { name: 'cristinei ', numero: '75 99985-3208 ' }
          , { name: 'María argentina', numero: '75999840736' }
          , { name: 'julia ', numero: '75 99985-3208 ' }
          , { name: 'Manasseis Sena Simões ', numero: '75 983468720' }
          , { name: 'Antônio Ricardo da Silva ', numero: '75999836491' }
          , { name: 'Marcos Pereira ', numero: '75998065771' }
          , { name: 'Mariza', numero: '75999574086' }
          , { name: 'Dulcimere Simões ', numero: '75998772636' }
          , { name: 'Julia Galdêncio ', numero: '75998772636' }
          , { name: 'Jucilene sena de Assis Carvalho ', numero: '75983463727' }
          , { name: 'Taiane ', numero: '75991646349' }
          , { name: 'Júlia', numero: '75983463727' }
          , { name: 'Jouse ', numero: '75988058276' }
          , { name: 'Jouse Teles ', numero: '75988058276' }
          , { name: 'Vinícius Winck do Nascimento', numero: '71994099875' }
          , { name: 'Alan santos ribeiro', numero: '71999915343' }
          , { name: 'José Raildo dos Santos Oliveira ', numero: '99864493' }
          , { name: 'Priscila Marques', numero: '71999990949' }
          , { name: 'Valdir cordeiro ', numero: '71 981876407 ' }
          , { name: 'Débora Moreira', numero: '75991221377' }
          , { name: 'Brenda Karoline Santos Ferreira Amorim', numero: '75981380323' }
          , { name: 'Beatriz ', numero: '75 983531963' }
          , { name: 'Tays', numero: '7599839984' }
          , { name: 'Suellen ', numero: '75992600607' }
          , { name: 'Julia Gaudêncio ', numero: '75982410638' }
          , { name: 'Mariana Santos Amy Reis ', numero: '75992995854' }
          , { name: 'Ivonildes Silva Santos', numero: '75 98847 5635' }
          , { name: 'Raimunda de Jesus Conceição ', numero: '97038940' }
          , { name: 'Julia gaudencio', numero: '998535933' }
          , { name: 'Roziane Cardoso da Silva ', numero: '75998503302' }
          , { name: 'Noemia Tavares de Souza ', numero: '75 9971-8852 ' }
          , { name: 'Raíssa Dias', numero: '75998465320' }
          , { name: 'Valmira', numero: '75982894293' }
          , { name: 'Jaquiara Aquino ', numero: '71984848123' }
          , { name: 'Raquel da Cruz Santana Rodrigues ', numero: '75981125725' }
          , { name: 'Danielle Silva Santos ', numero: '75998707399' }
          , { name: 'Bruno dos Santos Andrade ', numero: '71999189853' }
          , { name: 'Agnes Inês ', numero: '71982340640' }
          , { name: 'Auricena Barreto Ferreira ', numero: '75998386286' }
          , { name: 'Auricena Barreto Ferreira ', numero: '75998386286' }
          , { name: 'Glauber Silva ', numero: '75998678672' }
          , { name: 'Erick da Silva Marques ', numero: '75982978121' }
          , { name: 'Alexsandra Paulo', numero: '75982912728' }
          , { name: 'Sidney costa', numero: '75 998686480' }
          , { name: 'Jussara silva ', numero: '75999632128' }
          , { name: 'Aline Santos Silva ', numero: '75981763766' }
          , { name: 'Julia Gaudêcia', numero: '75988558816' }
          , { name: 'Sueli de santana dos Santos ', numero: '75 9 9935-2005 ' }
          , { name: 'Carla Emanuella Ferreira Sobreira ', numero: '88999995510' }
          , { name: 'Evanilda Silva Peixoto ', numero: '75 98205-7411 ' }
          , { name: 'Cleuza santana ', numero: '75991142240' }
          , { name: 'Anne hair ', numero: '75999500047' }
          , { name: 'DANIELA SOUSA SANTOS ', numero: '75983446244' }
          , { name: 'Wellington Pimentel dos Santos ', numero: '75 99122-0306 ' }
          , { name: 'Jaíra Costa ', numero: '75999955271' }
          , { name: 'Edmea Araujo Amorim', numero: '75 988230662' }
          , { name: 'Evanilson araujo santos', numero: '75 98154-0145' }
          , { name: 'Rute da Silva Araújo ', numero: '75 998614319' }
          , { name: 'Daise de Araujo Pereira ', numero: '75981134698' }
          , { name: 'Talita dos Santos ', numero: '75991872690' }
          , { name: 'Clara Laísla ', numero: '75983701753' }
          , { name: 'Monalisa Araujo Silva ', numero: '75998766704' }
          , { name: 'Adlaine Costa da Silva Santos ', numero: '75999566277' }
          , { name: 'Maiellemarxes', numero: '75999454700' }
          , { name: 'Marivaldo Souza Lima ', numero: '75999603180' }
          , { name: 'Leandro Lins Flores ', numero: '75991358803' }
          , { name: 'Jose Adriano Barbosa Pereira  ', numero: '075 998890339' }
          , { name: 'Sandela Nunes da Silva castro ', numero: '75 981667772' }
          , { name: 'Jose Renilson de santana Lima ', numero: '75982555227' }
          , { name: 'Marcos Vinícius Almeida Santos ', numero: '75998451939' }
          , { name: 'Andrei dos Santos Silva ', numero: '75988050228' }
          , { name: 'José Joscimar Lima Vieira ', numero: '75982434555' }
          , { name: 'Everton dos Santos Avelar ', numero: '75988434181' }
          , { name: 'Stefani hiaminique dos santos de carvalho', numero: '75991392633' }
          , { name: 'ANDREA DE JESUS ARAUJO ', numero: '75991464513' }
          , { name: 'Greyce Carla Chagas dos Santos ', numero: '75982183994' }
          , { name: 'Andrea de Jesus Araujo ', numero: '75999368753' }
          , { name: 'Amanda Menezes Santos', numero: '75 9 99946-4861 ' }
          , { name: 'Alana', numero: '75999646143' }
          , { name: 'Felipe Gonzalez ', numero: '85999210778' }
          , { name: 'Rafael de Santana ', numero: '75983678999' }
          , { name: 'Jose Souza Andrade ', numero: '75981154002' }
          , { name: 'Denis Cherles', numero: '85988067313' }
          , { name: 'Gabryel de Sousa Nunes ', numero: '55 8592440606' }
          , { name: 'Vanderlei rufino dos santos', numero: '75982225069' }
          , { name: 'Eliomara Almeida dos Santos', numero: '75982953301' }
          , { name: 'Andre de jesus santos', numero: '75 999393631' }
          , { name: 'Vanessa ', numero: '75 998073115' }
          , { name: 'João Pedro Schramm ', numero: '75981135217' }
          , { name: 'Kelle do Carmo Morais Neves ', numero: '75992127839' }
          , { name: 'Júlia quem foi o voto e meu nome é Rosemary Batista Costa ', numero: '75999011825' }
          , { name: 'Maions de Souza Santos', numero: '75999536223' }
          , { name: 'Andrea Rocha Dos Santos ', numero: '79998522207' }
          , { name: 'Evellyn kauane Rocha e Silva ', numero: '79998419007' }
          , { name: 'Ana Beatriz Rocha e Silva ', numero: '79996321009' }
          , { name: 'Edna silva santo ', numero: '75998763722' }
          , { name: 'Edna silva santos ', numero: '75998763722' }
          , { name: 'Nadson Araujo ', numero: '71997121678' }
          , { name: 'Ramiro Santana ', numero: '75999690025' }
          , { name: 'Érica Galdino de Lima ', numero: '71996885038' }
          , { name: 'Érica Galdino de Lima ', numero: '71996885038' }
          , { name: 'Ivonildes Silva dos Santos ', numero: '71992751152' }
          , { name: 'Antonio Geraldo da Silva Sá Barreto', numero: '71 996175381' }
          , { name: 'Adolfo Brandão ', numero: '71992460643' }
          , { name: 'Jirlene Lima de Jesus ', numero: '71997033532' }
          , { name: 'Joanna Vivian de Lima Santos', numero: '75983284194' }
          , { name: 'Priscila Santana de Oliveira ', numero: '7599839-2239' }
          , { name: 'Bruna Suellen Nunes Santos ', numero: '7599620688' }
          , { name: 'Geovane Conceição Guimarães ', numero: '75 99958-8200 ' }
          , { name: 'Gilmara dos santos conceição ', numero: '75999909207' }
          , { name: 'Amanda Barroso ', numero: '75982393119' }
          , { name: 'Stefane Luisa Oliveira Dos Santos ', numero: '75988853660' }
          , { name: 'Kezia Cerqueira ', numero: '75983002254' }
          , { name: 'Ludimylla Santos Cardim ', numero: '75999092910' }
          , { name: 'Maria Helena ', numero: '71996793882' }
          , { name: 'Sonia Mendes Cerqueira dos Santos ', numero: '75981918627' }
          , { name: 'Murilo Gomes ', numero: '71 9609-1579' }
          , { name: 'Alessandra', numero: '85996987022' }
          , { name: 'Marilyn Paiva do Nascimento ', numero: '85992018249' }
          , { name: 'Carlos Alejandro ', numero: '85994346679' }
          , { name: 'Lucas coresma da Silva', numero: '85996345682' }
          , { name: 'Lucimary Ribeiro Couto', numero: '71996259881' }
          , { name: 'Glauber Silva Araújo ', numero: '75998678672' }
          , { name: 'Edmar da silva Ribeiro ', numero: '91101877' }
          , { name: 'Talita ', numero: '71986851206' }
          , { name: ' Bruno Schramm', numero: '71986851206' }
          , { name: 'Adelir ', numero: '75 981624601 ' }
          , { name: 'Natiele santos de jesus', numero: '75982099932' }
          , { name: 'Anderson Catarino dos Reis ', numero: '75997149905' }
          , { name: 'Alailton Regis dos Santos', numero: '75988191398' }
          , { name: 'Rafael de Santana ', numero: '75983678999' }
          , { name: 'Paula Glayce de Araujo Santos ', numero: '75981381409' }
          , { name: 'Geovany Souza ', numero: '75999830457' }
          , { name: 'Jamile', numero: '75988242950' }
          , { name: 'Caroline dos Santos ', numero: '75 9 81858093' }
          , { name: 'Caroline ', numero: '75988823730' }
          , { name: 'Milena de Araújo Santos ', numero: '75992064742' }
          , { name: 'Anataela ', numero: '75997161356' }
          , { name: 'Edvaldo dos Anjos Filho ', numero: '75 999541233' }
          , { name: 'Erick ', numero: '75981158125' }
          , { name: 'Celucia Carvalho', numero: '75998629288' }
          , { name: 'Evelyn ', numero: '81049285' }
          , { name: 'Kaiane Souza dos Santos ', numero: '75983221602' }
          , { name: 'Italo da Conceição Rocha ', numero: '75982100927' }
          , { name: 'Adrielle Bispo dos Santos ', numero: '75982310763' }
          , { name: 'Talita dos santos ', numero: '75 999939308 ' }
          , { name: 'Magno oliveira de Souza ', numero: '75982662964' }
          , { name: 'Lindinalva Santana de Jesus Santos Silva ', numero: '75982823561' }
          , { name: 'Miguel Rastelly ', numero: '75991773571' }
          , { name: 'Thuana Oliveira ', numero: '75998657079' }
          , { name: 'Thuana Oliveira', numero: '75991902293' }
          , { name: 'Antonio Moura ', numero: '75982821142' }
          , { name: 'Eva Vilma santana', numero: '75999923548' }
          , { name: 'Jucileide ', numero: '75981716666' }
          , { name: 'Nayla Kertlen', numero: '7598321 0509' }
          , { name: 'Stephaine carvalho santos', numero: '75997007797' }
          , { name: 'Luis Claudio ', numero: '75998303020' }
          , { name: 'BRUNO FARIAS', numero: '75999345149' }
          , { name: 'Maria Helena dos Santos ', numero: '71996793882' }
          , { name: 'Emille Lauanne Oliveira Silva ', numero: '75998754621' }
          , { name: 'Ludilaine Santos de Santana ', numero: '75983468908' }
          , { name: 'Brena Luiza ', numero: '75982016707' }
          , { name: 'Dayse reis da silva', numero: '75999266286' }
          , { name: 'Rosenaldo Vital de Mello ', numero: '75991168504' }
          , { name: 'Alcione Menezes de Matos', numero: '75999662366' }
          , { name: 'Stefani Hiaminique dos Santos de Carvalho ', numero: '75991392633' }
          , { name: 'Madjane lisboa  oliveira ', numero: '759981671351' }
          , { name: 'samuel', numero: '75991474448' }
          , { name: 'Sonia Mendes Cerqueira dos Santos ', numero: '75 981918627' }
          , { name: 'Rafael Silva Barreto ', numero: '75991555845' }
          , { name: 'Kleber silveira Barbosa ', numero: '75999824874' }
          , { name: 'Soraia de sena simoes ', numero: '71983604756' }]
    }
  },
  computed: {
    filteredInscritos() {
      if(this.filterText === '') return this.inscritos;

      return this.inscritos.filter(inscrito =>
        inscrito.codigo.toString().toLowerCase() === this.filterText.toLowerCase()
      );
    },
    paginatedInscritos() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredInscritos.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredInscritos.length / this.itemsPerPage);
    }
  },
  methods: {
    sortear() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.numeroSorteado = this.definirNumeroSorteado();
        this.inscritos.find(inscrito => {
          if(inscrito.codigo === this.numeroSorteado) {
            this.ganhador = inscrito;
            return true;
          }
        });
      }, 5000);
    },
    definirNumeroSorteado() {
      return Math.floor(Math.random() * 100) + 1;
    }
  },
  created() {
    this.inscritos = this.inscritos.map((inscrito, index) => ({
      ...inscrito,
      codigo: index + 1
    }));
  }
}
</script>

<style scoped>
.sorteio {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.loading,
.resultado {
  margin-bottom: 20px;
  font-size: 20px;
}

.v-btn {
  width: 100px;
}

.myButton {
	box-shadow:inset 0px 1px 0px 0px #cf866c;
	background:linear-gradient(to bottom, #d0451b 5%, #bc3315 100%);
	background-color:#d0451b;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:18px;
	padding:12px 28px;
	text-decoration:none;
	text-shadow:0px 1px 0px #854629;
}
.myButton:hover {
	background:linear-gradient(to bottom, #bc3315 5%, #d0451b 100%);
	background-color:#bc3315;
}
.myButton:active {
	position:relative;
	top:1px;
}



.myButton2 {
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #ededed 5%, #dfdfdf 100%);
	background-color:#ededed;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#777777;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
}
.myButton2:hover {
	background:linear-gradient(to bottom, #dfdfdf 5%, #ededed 100%);
	background-color:#dfdfdf;
}
.myButton2:active {
	position:relative;
	top:1px;
}

.custom_input {
	display: flex;
	align-items: center;
	position: relative;
	max-width: 100%;
}

.input {
	font-size: 18px;
	padding: 5px 10px;
	width: 100%;
	padding-left: 35px;
	outline: none;
	background: #FFFFFF;
	color: #000000;
	border: 1px solid #C4D1EB;
	border-radius: 5px;
	box-shadow: 3px 3px 2px 0px #E2E2E2;
	transition: .3s ease;
}

.input:focus {
	background: #F2F2F2;
	border: 1px solid #5A7EC7;
	border-radius: 10px;
}

.input::placeholder {
	color: #DDDDDD;
}
</style>
